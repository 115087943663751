<template>
  <docs-layout>
    <div class="space-y-4 pb-10 w-full text-left">
      <p>Normally we would use Input Checkbox Group instead of Input Checkbox</p>
      <h2 class="text-2xl font-bold">Input checkbox</h2>
      <p class="font-bold">Default</p>
      <div class="w-1/4">
        <asom-input-checkbox 
          :value="sampleValue"
          v-model="sampleChecked"
          label="Sample checkbox"
          description="Sample description of checkbox"
        />
      </div>
      <p>Checked: {{sampleChecked}}</p>
      
      <p class="font-bold">States</p>
      <div class="w-1/4 space-y-4">
        <asom-input-checkbox
          checked
          state="default"
          label="Default state"
          description="Sample description of checkbox"
        />
        <asom-input-checkbox
          checked
          state="error"
          label="Error state"
          description="Sample description of checkbox"
        />
      </div>

      <p class="font-bold">Disabled</p>
      <div class="w-1/4 space-y-4">
        <asom-input-checkbox
          checked
          disabled
          label="Disabled checkbox"
          description="Sample description of checkbox"
        />
      </div>
    </div>
    <div class="space-y-4 pb-10 w-full text-left">
      <h2 class="text-2xl font-bold">Input checkbox group</h2>
      <p class="font-bold">Default</p>
      <div class="w-1/4">
        <asom-input-checkbox-group
          v-model="sampleGroupValue"
          :options="sampleGroupOptions"
        />
      </div>
      <p>Values: {{sampleGroupValue}}</p>
      <p class="font-bold">States</p>
      <div class="w-full space-y-4">
        <asom-input-checkbox-group
          state="default"
          v-model="groupValue1"
          :options="sampleGroupOptions"
        />
        <asom-input-checkbox-group
          state="error"
          v-model="groupValue3"
          :options="sampleGroupOptions"
        />
      </div>
      <p class="font-bold">Disabled</p>
      <div class="w-1/4">
        <asom-input-checkbox-group
          disabled
          v-model="groupValue4"
          :options="sampleGroupOptions"
        />
      </div>
      <p class="font-bold">Horizontal display</p>
      <div class="w-full">
        <asom-input-checkbox-group
          horizontal
          v-model="groupValue5"
          :options="sampleGroupOptions"
        />
      </div>
    </div>
  </docs-layout>
</template>
<script>
import DocsLayout from '@/docs/DocsLayout.vue'

export default {
  components: {
    DocsLayout
  },
  data() {
    return {
      sampleValue: '12343',
      sampleChecked: true,
      sampleGroupValue: [],
      sampleGroupOptions: [
        { value: '1', label: 'Option 1', description: 'Sample description' },
        { value: "2", label: 'Option 2', description: 'Sample description' },
        { value: '3', label: 'Option 3', description: 'Sample description' },
        { value: '4', label: 'Option 4', description: 'Sample description' },
      ],
      groupValue1: [],
      groupValue2: [],
      groupValue3: [],
      groupValue4: [ '1', '2', '3' ],
      groupValue5: []
    }
  },
  methods: {
    onSampleInputChange() {
      this.sampleChecked = !this.sampleChecked
    }
  }
}
</script>